<template>
  <div>
    <div class="add_box changePass">
      <el-form
        ref="postForm"
        :model="postForm"
        :rules="rulesPass"
        class="demo-ruleForm"
        label-width="150px"
        :inline="true"
        size="mini"
      >
        <el-form-item label="Enterprise User" prop="pin">
          <el-input
            v-model.trim="postForm.pin"
            type
            placeholder="Please enter Enterprise User"
            size="mini"
            maxlength="100"
          />
        </el-form-item>
        <el-form-item label="New Password" prop="pwd">
          <el-input
            v-model.trim="postForm.pwd"
            type
            placeholder="Please enter New Password"
            size="mini"
            maxlength="16"
          />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button
          
          type="primary"
          size="mini"
          @click="updateDataP('passForm')"
        >
          Modify
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { changePassword } from '@/api/user/list';

const defaultForm = {
  pin: '',
  pwd: ''
};
export default {
  name: 'ChangePassword',
  // 引入局部刷新
  inject: ['reload'],
  data() {
    return {
      postForm: Object.assign({}, defaultForm),
      // 获取URL信息
      outeObject: this.$route.query,
      // 重置密码时校验内容
      rulesPass: {
        pin: [
          {
            required: true,
            message: 'Please Enter Enterprise User',
            trigger: 'blur'
          }
        ],
        pwd: [
          {
            required: true,
            message: 'Please Enter New Password',
            trigger: 'blur'
          },
          {
            min: 6,
            max: 16,
            message: 'The password length must more than 6 and less than 16 bit',
            trigger: 'blur'
          },
          {
            pattern: /^(?![^a-zA-Z]+$)(?!\D+$)/,
            message: 'must contain number & letter',
            trigger: 'blur'
          }
        ]
      }
    };
  },
  watch: {},
  created() {
    if(this.outeObject.cont){
      this.postForm.pin = this.outeObject.cont.pin;
    }
   
  },
  methods: {
    updateDataP() {
      this.$refs['postForm'].validate(valid => {
        if (valid) {
          //console.log(this.postForm);
          changePassword(this.postForm).then(res => {
            //console.log(res);
            if (res.code == 200) {
              this.$message({
                message: 'Password Modify Success',
                type: 'success'
              });
              setTimeout(() => {
                this.postForm = Object.assign({}, defaultForm);
              }, 1000);
            } else {
              this.$message({
                message: res.msg,
                type: 'error'
              });
            }
          });
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.add_box {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: 20px 10px;
  box-sizing: border-box;
  background: #fff;
  ::v-deep .el-form-item__content {
    width: 200px;
  }
  h2 {
    height: 20px;
    font-size: 20px;
    font-family: Arial;
    font-weight: normal;
    color: rgba(36, 37, 38, 1);
  }
}
.dialog-footer {
  display: flex;
  justify-content: center;
}
::v-deep .el-form {
  margin-bottom: 20px;
}
/deep/.el-button {
  // padding: 0 12px !important;
  width: 100px;
  font-family: ArialMT;
  font-size: 14px;
}

::v-deep .el-button--primary {
  color: #fff;
  background-color: #2262ff;
  border-color: #2262ff;
}
</style>
